"use client";

import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const tagVariants = cva(
    "inline-flex items-center rounded-full border px-2.5 py-0.5 font-mono font-semibold text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default:
                    "border border-black border-opacity-5 bg-neutral-100 text-base-foreground dark:bg-neutral-800",
                secondary:
                    "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                outline: "text-foreground",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    },
);

export interface TagProps
    extends React.HTMLAttributes<HTMLButtonElement>,
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof tagVariants> {}

function Tag({ className, variant, ...props }: TagProps) {
    return <button className={cn(tagVariants({ variant }), className)} {...props} />;
}

export { Tag, tagVariants };
