// TODO: to be removed or refactored. yet to be decided

import Link from "next/link";
import me from "public/static/avatar.jpg";

export const avatar = me;
export const name = "Sam Wibb";

export const about = () => {
    return (
        // <>
        //     I'm deeply involved in a diverse range of exciting <Link href='/projects'>projects</Link> and <Link href='/writing'>interests</Link>.
        // </>
        <>
            I'm exploring the interchangeable roles between brain research and AI development. As AI
            envolves towards AGI, I view this intersection as a field for deepening my neuroscience
            knowledge while improving my neuroplasticity. I'm also deeply involved in a diverse
            range of exciting <Link href="/projects">projects</Link> and interests.
        </>
    );
};

export const bio = () => {
    const yearsSinceStart = new Date().getFullYear() - new Date("1995").getFullYear();

    return (
        <>
            As a developer, I've been writing code for over{" "}
            <Link href="/dev">{yearsSinceStart - 1} years</Link>. While my expertise spans across
            the full software development stack, I am particularly passionate about UI Development.
        </>
    );
};
