"use client";

import type { ReactNode } from "react";
import Balancer from "react-wrap-balancer";

import { cn } from "@/lib/utils";
import { withHeadingId } from "./utils";

type H3Props = {
    isGradient?: boolean;
    className?: string;
    children: ReactNode;
};

export default function H3({ children, className, isGradient = false, ...props }: H3Props) {
    return (
        <h3 className={cn({ "text-gradient": isGradient }, className)} {...props}>
            <Balancer>{withHeadingId(children)}</Balancer>
        </h3>
    );
}
