import type * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/lib/utils";

type TooltipPrimitiveProps = React.ComponentProps<typeof TooltipPrimitive.Root>;
type TooltipProps = TooltipPrimitiveProps &
    React.ComponentProps<typeof TooltipPrimitive.Content> & {
        content: string;
        children: React.ReactNode;
        className?: string;
    };

export function Tooltip({ content, children, className, ...props }: TooltipProps) {
    return (
        <TooltipPrimitive.Provider delayDuration={0} {...props}>
            <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>

                <TooltipPrimitive.Content
                    {...props}
                    className={cn(
                        "fade-in-25 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1 z-50 animate-in overflow-hidden rounded-md bg-neutral-200 px-3 py-1.5 font-sans text-popover-foreground text-sm shadow-md dark:bg-neutral-900",
                        className,
                    )}
                >
                    <TooltipPrimitive.Arrow className="animate-in fill-neutral-200 dark:fill-neutral-900" />
                    {content}
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
}
