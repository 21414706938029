"use client";

import Link, { type LinkProps as NextLinkProps } from "next/link";
import Image from "next/image";
import { name, avatar } from "@/lib/info";

interface LogoProps extends NextLinkProps {
    className?: string;
}

export default function Logo(props: LogoProps) {
    return (
        <Link {...props} aria-label="Sam Wibb" className={props.className}>
            <Image
                alt={name}
                className="mask mask-squircle grayscale"
                src={avatar}
                placeholder="blur"
                width={40}
                priority
            />
        </Link>
    );
}
