"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

export function Breadcrumbs() {
    const pathname = usePathname() || "";
    const paths = pathname.split("/").slice(1, -1);

    return (
        <nav>
            <ol>
                {paths.map((path) => (
                    <li key={path} className="flex items-center space-x-3">
                        <Link
                            href={`${path === "" ? "/" : `/${path}`}`}
                            className="text-neutral-800 capitalize transition-all ease-linear dark:text-neutral-400"
                            area-label={path}
                        >
                            {path === "" ? "home" : path}
                        </Link>
                        <div
                            aria-hidden="true"
                            className="h-3 w-px rotate-12 rounded-full bg-neutral-400"
                        />
                    </li>
                ))}
            </ol>
        </nav>
    );
}
