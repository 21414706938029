"use client";

import * as React from "react";
import { cn } from "@/lib/utils";
import type { TableOfContents } from "@/lib/toc";
import { useMounted } from "@/hooks";

type TocProps = {
    toc: TableOfContents;
};

export function DashboardTableOfContents({ toc }: TocProps) {
    const itemIds = React.useMemo(
        () =>
            toc.items
                ? toc.items
                      .flatMap((item) => [item.url, item?.items?.map((item) => item.url)])
                      .flat()
                      .filter((id): id is string => Boolean(id))
                      .map((id) => id.split("#")[1])
                : [],
        [toc],
    );

    const activeHeading = useActiveItem(itemIds);
    const mounted = useMounted();

    if (!toc?.items || !mounted) {
        return null;
    }

    return (
        <div className="space-y-2">
            <p className="font-medium">On This Page</p>
            <Tree tree={toc} activeItem={activeHeading} />
        </div>
    );
}

function useActiveItem(itemIds: string[]) {
    const [activeId, setActiveId] = React.useState<string | null>(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                // biome-ignore lint/complexity/noForEach: <explanation>
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            { rootMargin: "0% 0% -80% 0%" },
        );

        // biome-ignore lint/complexity/noForEach: <explanation>
        itemIds?.forEach((id) => {
            const element = document.getElementById(id);
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            // biome-ignore lint/complexity/noForEach: <explanation>
            itemIds?.forEach((id) => {
                const element = document.getElementById(id);
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [itemIds]);

    return activeId;
}

type TreeProps = {
    tree: TableOfContents;
    level?: number;
    activeItem?: string | null;
};

function Tree({ tree, level = 1, activeItem }: TreeProps) {
    return tree?.items?.length && level < 3 ? (
        <ul className={cn("m-0 list-none", { "pl-4": level !== 1 })}>
            {tree.items.map((item) => {
                return (
                    <li key={item.url} className={cn("mt-0 pt-2")}>
                        <a
                            href={item.url}
                            className={cn(
                                "inline-block no-underline transition-colors hover:text-foreground",
                                item.url === `#${activeItem}`
                                    ? "font-medium text-foreground"
                                    : "text-base-foreground",
                            )}
                        >
                            {item.title}
                        </a>
                        {item.items?.length ? (
                            <Tree tree={item} level={level + 1} activeItem={activeItem} />
                        ) : null}
                    </li>
                );
            })}
        </ul>
    ) : null;
}
