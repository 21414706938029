type CalloutProps = {
    emoji?: string;
    text?: string;
    children?: React.ReactNode;
};

export default function Callout({ emoji, text, children }: CalloutProps) {
    return (
        <div className="my-6 flex items-start rounded bg-base p-3 text-base-foreground text-md">
            <span className="mr-3 block w-6 scale-[1.2] text-center">{emoji}</span>
            <span className="block grow">{text ?? children}</span>
        </div>
    );
}
