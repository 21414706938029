"use client";

import Balancer from "react-wrap-balancer";
import type { ReactNode } from "react";

export default function Caption({ children }: { children: ReactNode }) {
    return (
        <span className="my-3 block w-full text-center font-mono text-base-foreground text-xs leading-normal">
            <Balancer>
                <span className="[&>a]:post-link">{children}</span>
            </Balancer>
        </span>
    );
}
