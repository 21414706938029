"use client";

import type * as React from "react";

export type SectionProps = {
    title?: string;
    children: React.ReactNode;
};

const ListSection: React.FC<SectionProps> = ({ title, children }) => {
    return (
        <fieldset aria-labelledby={title} className="my-6">
            <h3 id={title} className="mx-[2px] mb-2 font-mono text-base-foreground text-sm">
                {`// ${title}`}
            </h3>
            <ul>{children}</ul>
        </fieldset>
    );
};

export default ListSection;
