import type { ReactNode } from "react";
import Link, { type LinkProps } from "next/link";

type AProps = LinkProps & {
    id?: string;
    href: string;
    children: ReactNode;
    className?: string;
};

const A = (props: AProps) => {
    const href = props.href;
    const isInternalLink = href.startsWith("/");
    const isAnchorLink = href.startsWith("#");

    if (isInternalLink) {
        return <Link {...props}>{props.children}</Link>;
    }

    if (isAnchorLink) {
        return <a {...props} />;
    }

    return <a target="_blank" rel="noopener noreferrer" {...props} />;
};

export default A;
