export function ProsCard({ title, pros }: { title: string; pros: string[] }) {
    return (
        <div className="my-4 w-full rounded-xl border border-primary bg-neutral-50 p-6 dark:border-emerald-900 dark:bg-neutral-900">
            <span>{`You might use ${title} if...`}</span>
            <div className="mt-4">
                {pros.map((pro) => (
                    <div key={pro} className="mb-2 flex items-baseline font-medium">
                        <div className="mr-2 h-4 w-4">
                            <svg className="h-4 w-4 text-primary" viewBox="0 0 24 24">
                                <title>Pro</title>
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                                    <path d="M22 4L12 14.01l-3-3" />
                                </g>
                            </svg>
                        </div>
                        <span>{pro}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export function ConsCard({ title, cons }: { title: string; cons: string[] }) {
    return (
        <div className="my-6 w-full rounded-xl border border-red-200 bg-neutral-50 p-6 dark:border-red-900 dark:bg-neutral-900">
            <span>{`You might not use ${title} if...`}</span>
            <div className="mt-4">
                {cons.map((con) => (
                    <div key={con} className="mb-2 flex items-baseline font-medium">
                        <div className="mr-2 h-4 w-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="h-4 w-4 text-red-500"
                            >
                                <title>Con</title>
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </div>
                        <span>{con}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
