"use client";

import type * as React from "react";
import Link from "next/link";

import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Tag } from "../tag";

export type ItemProps = {
    title: string;
    description: string;
    left?: () => React.ReactNode;
    avatarUrl?: string;
    avatarFallback?: string;
    href?: string;
    isLastItem?: boolean;
};

const ListItem: React.FC<ItemProps> = ({
    title,
    description,
    left,
    avatarUrl,
    avatarFallback,
    href,
    isLastItem = false,
}) => {
    const borderClass = isLastItem ? "border-transparent" : "border-base";
    const fallbackCharacter = avatarFallback || title[0]?.toLowerCase() || "";
    const showAvatar = !left && (avatarUrl || avatarFallback); // Show avatar only if left is not provided and either avatarUrl or avatarFallback exists

    const content = (
        <div className="-mx-4 -my-[1px] group flex gap-x-4 rounded-md px-4 pt-4 hover:bg-base">
            {left ? (
                left()
            ) : showAvatar ? (
                <Avatar>
                    <AvatarImage src={avatarUrl} alt={`Avatar for ${title}`} />
                    <AvatarFallback>{fallbackCharacter}</AvatarFallback>
                </Avatar>
            ) : null}

            <div
                className={`grow border-b pb-4 text-base-foreground tracking-wider ${borderClass}`}
            >
                <h4 className="mb-[.8px] text-foreground text-sm">{title}</h4>

                <div className="flex justify-between gap-x-2 text-sm">
                    <p>{description}</p>

                    {/* Tag */}
                    {/* <div className="font-mono font-semibold bg-neutral-100 dark:bg-neutral-800 border border-black border-opacity-5 px-2 rounded-full tracking-wide text-xs">
                        tag
                    </div> */}

                    {/* <Tag>yes</Tag> */}
                </div>
            </div>
        </div>
    );

    return href ? (
        <Link href={href} className="flex flex-col no-underline active:scale-[.99]">
            {content}
        </Link>
    ) : (
        content
    );
};

export default ListItem;
