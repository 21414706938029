import type * as React from "react";
import { icons, type IconType } from "./icons";

interface IconProps extends React.SVGProps<SVGSVGElement> {
    name: IconType;
    width?: number;
    height?: number;
    isActive?: boolean;
}

export const Icon: React.FC<IconProps> = ({
    name,
    width = "1em",
    height = "1em",
    isActive,
    className,
    ...rest
}) => {
    // Retrieve the correct icon function based on the name
    const IconComponent = icons[name];

    const iconsUsingIsActive = ["home", "dev", "projects", "writing"];

    const iconProps = {
        ...(iconsUsingIsActive.includes(name) && { isActive }),
        className: `fill-current ${className}`,
        width,
        height,
        ...rest,
    };

    return <IconComponent {...iconProps} />;
};

export type { IconType };
