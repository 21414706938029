"use client";

import type { ReactNode } from "react";
import Balancer from "react-wrap-balancer";

import { cn } from "@/lib/utils";
import { withHeadingId } from "./utils";

type H1Props = {
    isGradient?: boolean;
    className?: string;
    children: ReactNode;
};

export default function H1({ children, className, isGradient = false, ...props }: H1Props) {
    return (
        <h1
            className={cn(
                "max-w-[702px] font-bold font-serif text-3xl",
                { "text-gradient": isGradient },
                className,
            )}
            {...props}
        >
            <Balancer>{withHeadingId(children)}</Balancer>
        </h1>
    );
}
