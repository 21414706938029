"use client";

import React from "react";
import { cn } from "@/lib/utils";

type TimelineBlockProps = {
    title: string;
    time: string;
    description: string;
    isLast?: boolean;
};

export function TimelineBlock({ title, time, description, isLast }: TimelineBlockProps) {
    return (
        <div className="mb-10 ml-4">
            <span className="-left-2.5 absolute mt-1.5 flex">
                {isLast && (
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-75" />
                )}
                <span
                    className={cn(
                        "relative inline-flex h-5 w-5 rounded-full border-4 border-background",
                        isLast ? "bg-primary" : "bg-base",
                    )}
                />
            </span>

            <time className="relative top-[1.5px] mt-1.5 mb-1 text-base-foreground text-xs uppercase tracking-wide">
                {time}
            </time>
            <h3 className="font-semibold text-foreground tracking-wide md:text-xl">{title}</h3>

            <p className="prose prose-neutral dark:prose-invert mt-4">{description}</p>
        </div>
    );
}

type TimelineProps = {
    timeline: TimelineBlockProps[];
};

export default function Timeline({ timeline }: TimelineProps) {
    return (
        <div className="my-10 pl-2">
            <div className="relative border-base border-l pl-6">
                {timeline
                    .map((block, index) => ({ ...block, originalIndex: index })) // Add the original index to each block
                    .sort((a, b) => b.originalIndex - a.originalIndex) // Sort the timeline array in reverse order based on original index
                    .map((block, index, arr) => (
                        <TimelineBlock
                            key={`${block.title}-${index}`}
                            {...block}
                            isLast={index === 0}
                        /> // if it's the first element after sorting, set isLast to true
                    ))}
            </div>
        </div>
    );
}
