"use client";

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { cn } from "@/lib/utils";
import Drawer from "./drawer";
import Logo from "../logo";
import { Tooltip } from "../tooltip";
import { Icon, type IconType } from "../icon";
import { Label } from "../label";
import { ThemeSwitch } from "../theme-switch";
import { ScrollToTop } from "../scroll-to-top";

// import { Breadcrumbs } from '../breadcrumbs'

export type NavItemProps = Record<
    string,
    {
        name: string;
        icon: IconType;
        className: string;
    }
>;

type LayoutProps = {
    children: React.ReactNode;
    items: NavItemProps;
};

export function Layout(props: LayoutProps) {
    const [top, setTop] = useState(true);

    let pathname = usePathname() || "/";
    const paths = ["/writing", "/projects"];

    paths.some((path) => {
        if (pathname.includes(path)) {
            pathname = path;
            return true;
        }
        return false;
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    }, [top]);

    const closeDrawer = () => {
        // Close drawer by clicking on the checkbox
        (document.getElementById("landing-drawer") as HTMLInputElement).checked = false;
    };

    const Navbar = () => {
        return (
            <div className="min-h-screen bg-background p-8 shadow-md sm:bg-clear sm:px-6 sm:pt-10 sm:shadow-none md:px-10 md:pt-20 lg:px-20">
                <Logo
                    href="/"
                    onClick={closeDrawer}
                    className="btn btn-square btn-ghost mask mask-squircle mb-8"
                />

                <nav className="grid place-items-center gap-y-2">
                    {Object.entries(props.items).map(([path, { name, icon, className }]) => {
                        const isActive = path === pathname;
                        return (
                            <Tooltip
                                key={name}
                                content={name.charAt(0).toUpperCase() + name.slice(1)}
                                side="right"
                                sideOffset={6}
                            >
                                <Link
                                    key={path}
                                    href={path}
                                    onClick={closeDrawer}
                                    aria-label={name}
                                    className={cn(
                                        "btn btn-square btn-link relative font-sans no-underline transition-all",
                                        {
                                            "font-bold text-base-foreground": !isActive,
                                            "pointer-events-none": isActive,
                                        },
                                    )}
                                >
                                    <span className="relative flex items-center p-[15px]">
                                        <Icon
                                            name={icon}
                                            width={24}
                                            height={24}
                                            isActive={isActive}
                                            className={cn(
                                                {
                                                    "text-foreground": isActive,
                                                },
                                                className,
                                            )}
                                        />

                                        <span className="sr-only ml-4">
                                            {name.charAt(0).toUpperCase() + name.slice(1)}
                                        </span>

                                        {path === pathname ? (
                                            <motion.div
                                                className="mask mask-squircle absolute inset-0 z-[-1] bg-base"
                                                layoutId="sidebar"
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 250,
                                                    damping: 25,
                                                }}
                                            />
                                        ) : null}
                                    </span>
                                </Link>
                            </Tooltip>
                        );
                    })}

                    {/* Divider hidden on mobile temporary */}
                    <div className="my-10 flex h-[.5px] w-[20px] self-center bg-border" />

                    <ThemeSwitch />
                </nav>

                <div className="absolute bottom-8 hidden self-center align-middle sm:bottom-10 sm:flex md:bottom-20">
                    <ScrollToTop />
                </div>
            </div>
        );
    };

    return (
        <Drawer id="landing-drawer" navbar={() => <Navbar />}>
            <header
                className={cn(
                    "sticky top-0 z-10 px-6 py-3 backdrop-blur-md sm:hidden",
                    !top && "shadow-md",
                )}
            >
                <Label
                    htmlFor="landing-drawer"
                    className="btn btn-square btn-ghost mask mask-squircle relative left-[-12px]"
                >
                    <Icon name="bars" width={32} height={32} />
                </Label>
            </header>

            {/* p-6 sm:p-10 md:pt-20 md:px-8 lg:px-20 */}

            <main className="flex min-h-screen flex-col">
                {/* <Breadcrumbs /> */}

                <div className="fixed right-8 bottom-8 z-50 sm:hidden">
                    <ScrollToTop />
                </div>

                {props.children}
            </main>
        </Drawer>
    );
}
