// @ts-nocheck

import * as React from "react";
import { useMDXComponent } from "next-contentlayer/hooks";

import components from "./mdx-components";

type MdxProps = {
    code: string;
    // tweets: Record<string, any>;
};

export function Mdx({ code, tweets }: MdxProps) {
    const Component = useMDXComponent(code);
    const StaticTweet = ({ id }) => {
        const tweet = tweets.find((tweet) => tweet.id === id);
        return (
            <></>
            // <Tweet {...tweet} />
        );
    };

    return (
        <article className="prose prose-neutral dark:prose-invert">
            <Component components={{ ...components, StaticTweet }} />
        </article>
    );
}
