import type { ReactNode } from "react";

// we use `[ul_&]` prefix for the <UL> variety
export default function LI({ children }: { children: ReactNode }) {
    return (
        <li
            className={`[ul_&]:before:-ml-4 my-2 [ul_&]:relative [ul_&]:pl-4 [ul_&]:before:absolute [ul_&]:before:mr-2 [ul_&]:before:text-base-foreground [ul_&]:before:content-['–']`}
        >
            {children}
        </li>
    );
}
