"use client";

import type { ReactNode } from "react";

export default function Code({ children }: { children: ReactNode }) {
    return (
        <code className="[p_&]:rounded [p_&]:bg-base [p_&]:px-1 [p_&]:py-0.5 [p_&]:text-sm">
            {children}
        </code>
    );
}
