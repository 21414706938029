"use client";

import type { ReactNode } from "react";

import A from "./a";
import P from "./p";

type FootNotesProps = {
    id: string;
    children: ReactNode;
    note: string;
};

export const FootNotes = ({ children }: { children: ReactNode }) => (
    <div className="before:content[''] font-mono text-base-foreground before:m-auto before:my-10 before:block before:w-[200px] before:border-base before:border-t">
        {children}
    </div>
);

export const Ref = ({ id }: { id: string }) => (
    <a
        href={`#f${id}`}
        id={`s${id}`}
        className="relative top-[-5px] text-base-foreground text-xs no-underline"
    >
        [{id}]
    </a>
);

export const FootNote = ({ id, note }: FootNotesProps) => (
    <P className="">
        {id}.{" "}
        <A href={`#s${id}`} id={`f${id}`} className="no-underline">
            ↑
        </A>{" "}
        {note}
    </P>
);
